<template>    

    <v-icon :class="color?'custom-color':''" :small="small" :color="color">{{compIcon}}</v-icon>

</template>

<script>
import string from '@lib/string'
import icon from '@app/icon'

//
// Usage: 
// import Icon from '@controls/icons/Icon'
// <Icon type="invoice"></Icon>
// 

export default {
        name: 'Icon',
        components: {},
        props: {
            icon: {
                type: [String],
                default: null
            },
            small: {
                type: [Boolean],
                default: false
            },
            type: {
                type: [String],
                default: ""
            },
            color: {
                type: [String],
                default: null
            },
        },
        computed: {
            compIcon: function() {
                if (string.isNotEmpty(this.icon)) {
                    return this.icon;
                }
                
                return icon.forType(this.type)
            },

        },
    }
</script>
