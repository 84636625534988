//
// Usage: 
//  import clsIcon from '@shared/lib/core/clsIcon'
//

class clsIcon {

    types = {

        "vendor":                         "icon-warehouse",
        "supplier":                       "icon-warehouse",
        "productgroup":                   "icon-warehouse",
        "tariff":                         "icon-money-coin",
        "employee_tariff":                "icon-money-coin",
        "rightsgroup":                    "icon-security",
        "purchaseinvoice":                "icon-invoice",
        "reject":                         "icon-trash",
        "paid":                           "icon-money-check",
        "unpaid":                         "icon-money-minus",
        "combine_amount":                 "icon-card-add1",

        "select":                         "icon-teacher",
        "expand":                         "icon-plus-rectangle",
        "collapse":                       "icon-minus-rectangle",

        "pen":                             "icon-pencil1",
        "smile":                           "icon-smile-rectangle",
        "sad":                             "icon-sad-rectangle",
        "pause":                           "icon-pause",
        "play":                            "icon-play",
        "stop":                            "icon-stop",
        "webhook":                         "icon-home-link",
        "package":                         "icon-package-02",
        "tasks":                           "icon-calendar-01",
        "accesslog":                       "icon-list-view-circle",             
        "takeout":                         "icon-out",
        "spam":                            "icon-sad-rectangle",
        "nospam":                          "icon-smile-rectangle",
        "delta":                           "icon-warning-polygon",
        "user":                            "icon-user-circle2",
        "security":                        "icon-shield",
        "note":                            "icon-sticker",
        "noteempty":                       "icon-sticker",
        "notefilled":                      "icon-sticker",
        "log":                             "icon-notebook",
        "dashboard":                       "icon-home-graph",
        "flag":                            "icon-flag",
        "flag-empty":                      "icon-flag",
        "menu-vertical":                   "icon-menu-circle-vertical-01",
        "drag":                             "icon-menu-line-horizontal",
        "menu-horizontal":                 "icon-menu-circle-vertical",
        "paymentorder":                    "icon-wallet-arrow-right",
        "hours":                           "icon-clock-circle-1",
        "subscription":                    "icon-file-reload",
        "workorder":                       "icon-notes-edit",
        "planning":                        "icon-calendar-check",
        "workorder":                       "icon-clipboard-activity",
        "orders":                          "icon-book",
        "order":                           "icon-book",
        "download":                        "icon-download",
        "company":                         "icon-files1",
        "warehouse":                       "icon-warehouse",
        "refresh":                         "icon-reload",
        "link":                            "icon-link",
        "unlink":                          "icon-link-unlink",
        "dropdown":                        "icon-direction-down-01",
        "dropdown-thin":                   "icon-direction-down-01",
        "plugin":                          "icon-plugin",
        "location":                        "icon-location-03",
        "accountancy":                     "icon-destination-02",
        "split":                           "icon-text-wrap",
        "calculator":                      "icon-calculator1",
        "loader":                          "icon-loading",
        "calendar":                        "icon-calendar",
        "calendar-plus":                   "icon-calendar-add",
        "calendar-minus":                  "icon-calendar-minus",
        "calendar-check":                  "icon-calendar-check",
        "calendar-remove":                 "icon-calendar-delete",
        "stopwatch":                       "icon-stopwatch",
        "stopwatch-plus":                  "icon-stopwatch-add",
        "stopwatch-minus":                 "icon-stopwatch-minus",
        "stopwatch-check":                 "icon-stopwatch-check",
        "stopwatch-remove":                "icon-stopwatch-delete",
        "like":                            "icon-like1",
        "dislike":                         "icon-dislike",
        "deal":                            "icon-deal",
        "check":                           "icon-tick",
//        "check":                           "icon-check-rectangle",
//        "uncheck":                         "icon-clear-rectangle",        
        "minus":                           "icon-minus",
        "alert":                           "icon-warning-polygon",
        "security-alert":                  "icon-shield-warning",
        "tip":                             "icon-information",
        "upload":                          "icon-upload",
        "person":                          "icon-people-01",
        "purchase":                        "icon-invoice",
        "tender":                          "icon-pencil-paper",
        "tender-sign":                     "icon-assignment",
        "bank":                            "icon-bank",
        "iban":                            "icon-card1",
        "i18n":                            "icon-globe-01",
        "chevrondown":                     "icon-direction-down-01",
        "chevronup":                       "icon-direction-up-01",
        "chevronright":                    "icon-direction-right-01",
        "chevronleft":                     "icon-direction-left-01",
        "up":                              "icon-arrow-up",
        "down":                            "icon-arrow-down",
        "arrowright":                      "icon-arrow-right",
        "arrowleft":                       "icon-arrow-left",
        "merge":                           "icon-community",
        "search-big":                      "icon-search-03",
        "search":                          "icon-search-01",
        "export":                          "icon-out",
        "import":                          "icon-warning-polygon",
        "error":                           "icon-warning-error",
        "notification":                    "icon-notification-03",
        "notifications-off":               "icon-notification-off",
        "bell":                            "icon-notification-ringing",
        "archive":                         "icon-archive-add",
        "unarchive":                       "icon-archive-clear",
        "invoice":                         "icon-file-01",
        "help":                            "icon-help",
        "confirm":                         "icon-check-mark-circle",
        "edit":                            "icon-edit",
        "open":                            "icon-edit",
        "edit-rect":                       "icon-edit-rectangle",
        "close":                           "icon-remove",
        "remove":                          "icon-trash",
        "delete":                          "icon-trash",
        "paraphs":                         "icon-check-mark-circle", 
        "paraph":                          "icon-check-mark-circle",
        "create":                          "icon-plus", 
        "add":                             "icon-plus", 
        "history":                         "icon-time-quarter-past", 
        "history2":                        "icon-time-half-past",
        "history3":                        "icon-time--quarter",
        "payment":                         "icon-wallet-add-01", 
        "reminder":                        "icon-fast-time",
        "reminder2":                       "icon-fast-time",
        "defaultnotice":                   "icon-rotate-lock",
        "print":                           "icon-qr-scan",
        "send":                            "icon-sent-fast",     
        "signal":                          "icon-signal",     
        "dna":                             "icon-dna2",     
        "brew":                            "icon-chemistry-021",
        "test":                            "icon-test-tube-11",
        "send-basic":                      "icon-send",         
        "email":                           "icon-mail-fast",    
        "mail":                            "icon-mail",         
        "save":                            "icon-disk",
        "pdf":                             "icon-file-pdf",
        "docs":                            "icon-file-doc",
        "jpg":                             "icon-file-jpg",
        "png":                             "icon-file-png",
        "xml":                             "icon-file-xml",
        "example":                         "icon-image",
        "attachment":                      "icon-attachment",
        "attachments":                     "icon-folder-01",
        "product":                         "icon-cart",
        "new-product":                     "icon-cart-add",
        "product":                         "icon-cart",
        "manday":                          "icon-fast-time",
        "mandays":                         "icon-fast-time",
        "project":                         "icon-briefcase-04",
        "projects":                        "icon-briefcase-04",
        "employee":                        "icon-user-polygon", 
        "relation":                        "icon-users-01", 
        "relations":                       "icon-users-01", 
        "setting":                         "icon-setting",                   
        "masterdata":                      "icon-layers1",        
        "settings":                        "icon-setting",                
        "copy":                            "icon-files",
        "copy2":                           "icon-copy",
        "copy2-check":                     "icon-copy-check",
        "pie-chart":                       "icon-chart-pie-01",       
        "back":                            "icon-remove-02",
        "credit":                          "icon-file-minus",
        "history":                         "icon-time-quarter-past",
        "show":                            "icon-eye",
        "hide":                            "icon-eye-disable",
        "authenticate":                    "icon-eye-scanner",
        "database":                        "icon-layers",
        "paragraph":                       "icon-paragraph",
        "maintenance":                     "icon-compass1",
        "apps":                            "icon-apps1",
        "widgets":                         "icon-widgets",
        "accessibility":                   "icon-accessibility",
        "rate":                            "icon-money-coin",
        "login":                           "icon-login-01",
        "logout":                          "icon-logout-01",
        "units":                           "icon-open-box-01",
        "discount":                        "icon-ticket-percent",
        "tag":                             "icon-tag-01",
        "file-format":                     "icon-notes-edit-favourite",
        "moon":                            "icon-half-moon",
        "sun":                             "icon-sun",
        "rate-increase":                   "icon-earnings",
        "reports":                         "icon-hart-arrow-up",
        "options":                         "icon-filter-06",
        "filter":                          "icon-filter-07",
        "department":                      "icon-menu-user",
        "unlock":                          "icon-unlock1",
        "lock":                            "icon-lock1",
        "support":                         "icon-lifebuoy",
        "live-support":                    "icon-headphones",
        "flash":                           "icon-flash",
        "flash-automation":                "icon-flash-auto",
        "bold":                            "icon-bold",
        "italic":                          "icon-italic",
        "underline":                       "icon-underline",
        "heading":                         "icon-heading",
        "font-color":                      "icon-font-color",
        "font-size-t":                     "icon-font-size-01",
        "font-size-a":                     "icon-font-size",
        "striketrough":                    "icon-strikethrough",
        "striketrough":                    "icon-strikethrough",
        "striketrough-t":                  "icon-strikethrough-01",
        "align-left":                      "icon-align-left",
        "align-right":                     "icon-align-right",
        "align-center":                    "icon-align-center",
        "indent-increase":                 "icon-indent-increase",
        "indent-decrease":                 "icon-indent-decrease",
        "bullet-list":                     "icon-list-view-circle",
        "exchange":                        "icon-exchange",
        "back":                            "icon-arrow-back",
        "forward":                         "icon-arrow-return",
        "sort-up":                         "icon-sort-arrow-up",
        "sort-down":                       "icon-sort-arrow-down",
        "zoom-in":                         "icon-search-plus",
        "zoomout":                         "icon-zoom-out1",
        "enlarge":                         "icon-zoom-out1",
        "shrink":                          "icon-zoom-in1",
        "move":                            "icon-arrow-move",
        "transfer":                        "icon-arrow-transfer",
        "zoom-out":                        "icon-search-minus",
        "qr-scan":                         "icon-qr-scan",
        "star":                            "icon-star",
        "rotate-left":                     "icon-undo",
        "rotate-right":                    "icon-redo",
        "maximize":                        "icon-maximize",
        "minimize":                        "icon-minimize",
    };

    /**
     * Convert a type or specification or name to a concrete icon.
     */
    forType(str){
        if (!str) {
            str = "?";
        }
        return this.types[str] || null;
    }
};

export default clsIcon;